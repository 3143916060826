.mr-head h2 {
  line-height: 34px;
}
.ml-box p {
  font-size: 22px;
  line-height: 34px;
}
.toggle-password {
  position: absolute;
  top: 50%;
  right: 10px;
  color: #bfc5d2;
  font-size: 18px;
  transform: translateY(-50%);
  cursor: pointer;
}
.hero-background {
  background: url('./../images/SignupLogin.png');
}
.hero-background-sp1 {
  background: url('./../images/SignupLogin.png');
}
.hero-background-sp2 {
  background: url('./../images/signup/signup login-2.png');
}
.hero-background-sp4 {
  background: url('./../images/signup/signup login-4.png');
}

.hero-background-sp3 {
  background: url('./../images/signup/signup login-3.png');
}
.hero-background1 {
  background: url('./../images/signup/signup login-1.png');
}
.hero1-background {
  background: url('./../images/hero-1.png');
}
.cb-left-img {
  background: url('./../images/cb-left-img.jpg');
  background-size: cover !important;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  position: relative;
}

.cb-right-img {
  background: url('./../images/cb-right-img.jpg');
  background-size: cover !important;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  position: relative;
}
.Toastify__toast-container {
  width: auto !important;
}
.alert.alert-white {
  border-radius: 4px !important;
}
.fa-exclamation-circle.alert-red {
  color: #ba0303 !important;
}
.react-datepicker-wrapper {
  width: 100% !important;
}

.member-bg {
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.custom-input label.error {
  width: 100%;
}

.errorColor {
  border-color: #eb5757;
}
label.errorLabel {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  letter-spacing: 0.4px;
  color: #ff0c3e;
  margin-bottom: 0;
}

a.errorLabel {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  letter-spacing: 0.4px;
  color: #ff0c3e;
  margin-bottom: 0;
  padding-top: 18px;
}
.nice-select {
  padding-top: 0px !important;
}
.pull-left {
  text-align: left !important;
}
.small {
  height: 40px !important;
  width: 130px !important;
  margin: 0px 10px;
}

.brand-logo {
  width: 138px !important;
}

.grey {
  color: #bdbdbd;
}

.p10-top {
  padding-top: 10px;
}
.testPurple {
  background: #621ac0 !important;
  max-width: 25%;
  z-index: 99999999 !important;
}

.imageClinic {
  border: #621ac0 1px solid;
  width: 150px;
  margin: 10px;
}
.form-groupxx > .css-noozwo-control {
  min-height: 60px !important;
}

.form-groupxx > .css-k7s5h2-control {
  min-height: 60px !important;
}
.css-1rhbuit-multiValue {
  background-color: hsl(0, 0%, 97.6%) !important;
}

.css-xb97g8:hover {
  background-color: rgba(95, 50, 187, 0.2);
  color: #de350b;
}
.completed:hover {
  background: white !important;
}
.form-check-inline {
  margin: 0px 10px !important;
  width: 100% !important;
}

.tab-sidebar-top h6 {
  font-size: 20px;
  color: #000;
  margin-bottom: 0;
}

.disabled-slot {
  background-color: #621ac0;
  opacity: 0.2;
  color: #bdbdbd !important;
}

.ReactTags__selected span.ReactTags__tag {
  margin-bottom: 10px;
  margin-right: 10px;
  min-width: auto !important;
}
.ReactTags__remove {
  margin-left: 7px;
  font-size: 14px;
  line-height: 14px;
  margin-right: -5px;
}

.imageClinic {
  border: #621ac0 1px solid;
  width: 100%;
  margin: 0;
  height: 100%;
}
.imagePreview {
  width: 100px;
  margin-right: 10px;
  margin-bottom: 10px;
  position: relative;
}

.imagePreviewBox {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 22px;
}

.imagePreviewClose {
  position: absolute;
  top: 2px;
  right: 5px;
  font-size: 16px;
  line-height: 20px;
  opacity: 0.7;
}
.cometchat--unified {
  height: 100vh !important;
}
.sender__message__container .message__txt__wrapper {
  background-color: #621ac0 !important;
}

.unread-count {
  background-color: #621ac0 !important;
}

.hsl-item {
  padding: 30px !important;
}

span > a:hover {
  outline: none;
  color: #f68e3f !important;
  text-decoration: none;
}
.hero-container {
  background-size: cover;
  background-position: center !important;
}

li.expert-item {
  padding: 15px 15px 0;
}
/*.modal-dialog {
  max-width: 1000px !important;
}*/
.custom-input label:not(.error) {
  color: gray;
}
@media (max-width: 1199.98px) {
  .d-none {
    display: none !important;
  }
}
.filter-dropdown-menu .filter-menu-item {
  width: 20% !important;
  padding: 0 15px;
}
.dashboard-top-bar {
  z-index: 999;
}
/* .nav li {
  padding: 0px !important;
} */
.pi-left {
  max-width: 55% !important;
}
.add-more-field {
  padding-left: 10px !important;
}
.leftbar {
  padding-left: 25px !important;
}
.nav li:not(:last-child):before {
  right: -25px !important;
}
.profiletab {
  cursor: pointer;
}
.opennote {
  right: 0px;
}
.closenote {
  right: -100%;
}
.sp-doc-box:before {
  background: #fff;
}
ul.tags li {
  width: 50% !important;
}
.br-seartch-bar .form-group::after {
  z-index: 998;
}

.separatorNew {
  display: inline-block;
  width: 100%;
  height: 1px;
  background: #e0e0e0;
}
.custom-modal .modal-dialog {
  max-width: 448px !important;
}
.notesopen {
  right: 0px;
}
.notesclose {
  right: -100%;
}
.form-check {
  padding-left: 0;
}
.radiobox-group .form-check:not(:last-child) {
  margin-right: 29px;
}
/* @media (max-width: 1400.98px) {
  .sidebar-box {
    width: 675px;
  }
} */
.form-check-label {
  margin-bottom: 6px;
}
.nav li:not(:last-child):before {
  right: 0px !important;
}
.custom-modal.custom-modal2 .modal-dialog {
  max-width: 754px !important;
}
.task {
  font-size: 12px;
  margin-bottom: 8px;
}
.mcb-body {
  min-height: 240px !important;
}
.profile {
  max-width: 350px !important;
}
.add-bg {
  cursor: pointer;
}

.main-content-box.main-content-box3 {
  width: 100% !important;
}
.gradient-bg {
  background: rgb(140, 86, 213);
  background: -moz-linear-gradient(
    left,
    rgba(140, 86, 213, 1) 0%,
    rgba(78, 12, 167, 1) 100%
  );
  background: -webkit-linear-gradient(
    left,
    rgba(140, 86, 213, 1) 0%,
    rgba(78, 12, 167, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(140, 86, 213, 1) 0%,
    rgba(78, 12, 167, 1) 100%
  );
}
/*.form-check-label {
  margin-left: 28px !important;
}*/
.fc .fc-toolbar.fc-header-toolbar {
  padding: 11px 20px 15px;
  border-bottom: 1px solid #d7dae2;
  height: 62px;
}

.fc .fc-timegrid-slot {
  height: 4em !important;
}

.dw-main-content {
  height: auto !important;
}

.fc .fc-timegrid-now-indicator-line {
  position: absolute;
  z-index: 4;
  left: 0;
  right: 0;
  border-style: solid;
  border-color: #3a7ef4 !important;
  border-color: #3a7ef4 !important;
  border-width: 1px 0 0;
  min-height: 2rem;
}

.slick-dots li button {
  border: 1px solid #fff;
}
.slick-dots li.slick-active button,
.slick-dots li:hover button {
  opacity: 1;
  background-color: #fff;
}
li .new-service-box p {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  line-height: 27px;
}
/*.bg-white {
  color: #f68e3f !important;
}*/
.profile-img {
  align-items: unset;
}
.person-details-box .person-img > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.np-img-box {
  width: 60px;
  height: 60px;
  overflow: hidden;
  margin-right: 20px;
  border-radius: 50%;
}
.field.on ~ .field.on::after {
  content: ', ';
}
.new-search-box:before {
  content: url(../images/material-search.svg);
  background: none;
}
.sidebar-body .form-control {
  height: 45px;
}
.css-1okebmr-indicatorSeparator {
  width: 0px !important;
}

body.dashboard-staff {
  padding-top: 70px;
}

.pannel-left-staff {
  width: 100% !important;
}
button.fc-prev-button,
button.fc-next-button {
  text-transform: capitalize !important;
  font-size: 13px !important;
  background-color: transparent !important;
  color: #180058 !important;
  border-color: #d7dae2 !important;
}

button.fc-today-button {
  text-transform: capitalize !important;
  font-size: 13px !important;
  background-color: #582bb81a !important;
  color: #582bb8 !important;
  border-color: #d7dae2 !important;
}

button.fc-dayGridMonth-button {
  text-transform: capitalize !important;
  font-size: 13px !important;
  background-color: transparent !important;
  color: #180058 !important;
  border-color: #d7dae2 !important;
}
button.fc-dayGridMonth-button.fc-button-active,
button.fc-timeGridWeek-button.fc-button-active,
button.fc-timeGridDay-button.fc-button-active {
  text-transform: capitalize !important;
  font-size: 13px !important;
  background-color: #582bb81a !important;
  color: #582bb8 !important;
  border-color: #d7dae2 !important;
}

button.fc-timeGridWeek-button,
button.fc-timeGridDay-button {
  text-transform: capitalize !important;
  font-size: 13px !important;
  background-color: transparent !important;
  color: #180058 !important;
  border-color: #d7dae2 !important;
}
.fc .fc-toolbar-title {
  font-size: 18px !important;
  color: #180058;
}
.fc .fc-toolbar.fc-header-toolbar {
  border-bottom: 0 !important;
  margin-bottom: 0 !important;
}
.no-border-no-padding {
  border-bottom: 0 !important;
  padding-bottom: 0 !important;
}
.fc .fc-col-header-cell-cushion {
  padding: 12px 4px !important;
  font-size: 11px;
  font-weight: 700;
  color: #180058;
}
.fc table {
  font-size: 13px !important;
}
.fc .fc-daygrid-day-bg .fc-bg-event {
  background: transparent;
}
/* td.fc-daygrid-day.fc-day.fc-day-sun {
    background: rgb(213 117 117 / 20%)!important;
} */
/* .fc-theme-standard td:first-child {
    border: 0;
} */
.fc .fc-timegrid-col-bg .fc-bg-event {
  background: #ebf9ff !important;
}
.fc-timegrid-now-indicator-line:before {
  content: '';
  width: 12px;
  height: 12px;
  background: #397ff4;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}
.fc .fc-timegrid-now-indicator-arrow {
  width: 0;
  height: 0;
  border-top: 5px solid transparent !important;
  border-left: 5px solid #397ff4 !important;
  border-bottom: 5px solid transparent !important;
}
.calendar-cradits {
  font-size: 13px;
}
.fc-button-group {
  box-shadow: 0px 2px 3px #0000000d;
}
body.dashboard {
  background: #f0f0f0;
}
/* @media (max-width: 1400.98px) {
  .sidebar-box {
    width: 675px;
  }
} */

@media (max-width: 1199.98px) {
  .d-none {
    display: none !important;
  }
}
@media (max-width: 767.98px) {
  .calendar-cradits {
    padding-bottom: 8px !important;
    padding-top: 8px !important;
    font-size: 13px;
  }
  .dm-footer {
    padding: 0 15px;
  }
}
/* .list-box {
  width: 50%;
} */
.incomplete {
  height: 39px !important;
}
.rg-row-content-right {
  width: 80%;
}
.follow-up2 {
  padding: 15px;
}
.prescription-form {
  height: 45px !important;
  padding-top: 0px !important;
}
.fdm-upper {
  max-height: 400px !important;
  overflow: scroll;
}
.cm-box.noarrow:before {
  display: none;
}
.mdb-intro-text {
  width: 90% !important;
  overflow: hidden !important;
}
.photo-upload-modal {
  max-width: 50% !important;
}
.outer {
  padding: 0px !important;
}
.form-check input[type='checkbox'] + .form-check-label {
  margin-left: 30px;
}
.chats-box .chats-head {
  margin-bottom: 6px !important;
}
.chat {
  padding-right: 5px !important;
}
/* .np-right {
  width: 28% !important;
} */

.np-right-test {
  width: 13% !important;
}
.fillup-btn-didable {
  background: #ccc;
}

.np-task {
  width: unset !important;
}
.dropright-body:before {
  display: none;
}
.person-details-box .person-img-noti > img {
  width: 20px;
  height: 20px;
  object-fit: cover;
}
.custom-dropdown ul li:not(:last-child):after {
  display: none !important;
}
.np-links-pres {
  cursor: pointer;
  padding: 10px 16px;
  border: 1px solid #f68e3f;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
li.sidebar-content-item {
  width: 12.5% !important;
}
@media (max-width: 991.98px) {
  .form-popup .modal-content {
    padding: 47px 15px 30px 15px !important;
    min-height: auto;
  }
}

@media (min-width: 991.98px) {
  .modal-dialog {
    max-width: 50% !important;
    margin-top: 45px;
  }
}

@media (max-width: 576px) {
  .modal-dialog {
    max-width: 100% !important;
    margin-top: 45px;
  }
}

li.sidebar-content-item {
  padding: 0 2px !important;
  word-wrap: break-word;
}
.modal-body-doc {
  max-height: 600px;
}
.sidebar-body embed {
  width: 100%;
  height: calc(100vh - 360px);
}
.top-logo {
  z-index: 99 !important;
}
.boldFont {
  font-weight: 700;
  color: #582bb8;
}
li.earning-item {
  width: 33% !important;
}
.page-item {
  cursor: pointer;
}
.cpl-wrapper-outer {
  overflow: visible;
}
.dc-block .form-check {
  margin-right: 20px !important;
}
.eQmQXs {
  z-index: 99999999999 !important;
}
.color.color {
  z-index: 999999999 !important;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  font-size: 15px;
  font-weight: 600;
  padding: 10px 10px 10px 5px;
  border-bottom: 1px solid #ebebeb !important;
  border: none;
  box-sizing: border-box;
  margin-top: 22px;
  box-shadow: none;
  font-family: Open Sans, sans-serif;
}
.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: #ff8f00;
}
.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background-color: #ff8f00 !important;
}
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 0.3rem;
  background-color: #ff8f00 !important;
}
.height-auto .chat-area {
  height: calc(100% - 56px);
}
.patient-btn a {
  padding: 0px 6px !important;
  height: 22px !important;
}
.fas {
  font-weight: 700 !important;
}
.ih-box:hover,
.mdb-intro-group:hover {
  background: rgb(88 43 184 / 15%);
}
.has-shadow li.cm-item:hover .cm-box {
  background: rgb(88 43 184 / 15%);
}
.fc-daygrid-day-events:hover {
  cursor: pointer;
}
.added-button {
  display: flex;
  justify-content: space-between;
}
.appointments-pannel-outer {
  height: auto !important;
}

.fc-scroller {
  overflow: visible;
}
.card {
  position: static;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.fc .fc-scroller {
  -webkit-overflow-scrolling: touch;
  position: relative;
  overflow: auto !important;
}
/* .slick-cloned {
  display: none !important;
} */
@media (max-width: 1199.98px) {
  .headingContact {
    font-size: 16px !important;
  }
}

@media (min-width: 1199.98px) {
  .slick-cloned {
    display: none !important;
  }
}

@media (max-width: 767.98px) {
  .content-container {
    padding: 35px 15px;
  }
}
@media (max-width: 420.98px) {
  .tp-text-box {
    flex-flow: column;
    align-items: center !important;
  }
}
@media (max-width: 611px) {
  ul.pannel-page-links {
    margin-bottom: 15px;
  }
}
@media (max-width: 1199.98px) {
  .profile-img {
    margin-left: 0;
  }
}
@media (max-width: 575.98px) {
  .ddm-inner-box span + div {
    flex-direction: row;
    align-items: flex-start !important;
  }
}
@media (max-width: 767.98px) {
  .patients-details-footer {
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 767.98px) {
  .patients-details-footer a {
    margin-bottom: 15px;
  }
}
@media (max-width: 575.2px) {
  .schedule-box {
    min-height: 52px;
    display: flex;
    flex-direction: column !important;
    align-items: flex-start;
    justify-content: flex-start;
  }
}
@media (max-width: 991.98px) {
  .dw-right .btn-outline,
  .dw-right .btn-default {
    width: 100%;
  }
}
@media (max-width: 575.98px) {
  .dw-right {
    display: block;
  }
}
@media (max-width: 767.2px) {
  .np-box .np-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 767.2px) {
  .np-box .np-right {
    display: flex;
    justify-content: center !important;
  }
}

@media (max-width: 767.2px) {
  .np-box .np-img-box {
    margin: 0 auto !important;
  }
}
@media (max-width: 767.2px) {
  .np-box .np-text-box {
    display: flex;
    flex-direction: column;
    align-items: center !important;
    justify-content: center !important;
  }
}

@media (max-width: 767.2px) {
  .np-box .nptb-left {
    display: flex;
    flex-direction: column;
    align-items: center !important;
    justify-content: center !important;
  }
}

@media (max-width: 767.2px) {
  .np-box .nptb-right {
    padding-left: 0;
  }
}
@media (max-width: 767.2px) {
  .nptb-left h5 > i {
    margin-left: 0px;
  }
}
@media (max-width: 767.2px) {
  .np-profile-list .np-item .np-box {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 767.2px) {
  .np-item .np-box .np-right {
    flex-wrap: nowrap !important;
    justify-content: center !important;
  }
}

@media (max-width: 767.2px) {
  .np-right-test {
    width: 100% !important;
  }
}

@media (max-width: 575.2px) {
  .earning-list {
    flex-direction: column;
    flex-wrap: nowrap;
  }
}
@media (max-width: 575.2px) {
  li.earning-item {
    width: 100% !important;
  }
}
@media (max-width: 575.2px) {
  .small {
    height: 40px !important;
    width: 80% !important;
    margin: 0px !important;
  }
}
@media (max-width: 575.98px) {
  .pd-right-content {
    margin-left: 0;
  }
}
@media (max-width: 575.98px) {
  .ddm-inner-box span + div {
    flex-direction: column;
    align-items: flex-start !important;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: visible !important;
}
.chatbot-body .active .card-header:after {
  font-size: 0 !important;
}
.active .card-header:after {
  font-size: 0px !important;
}
.fillup-btn-didable {
  cursor: not-allowed;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
  height: 380px !important;
}
.notes-box {
  cursor: auto !important;
}
.tp-date-desc {
  min-width: 265px !important;
}
.fc .fc-scroller-harness-liquid {
  overflow-y: auto !important;
}
/* .np-box .np-right {
  width: 378px !important;
} */
@media (max-width: 1670.98px) {
  .sidebar-body {
    padding: 25px;
    height: calc(100% - 60px);
  }
}
@media (max-width: 1670.98px) {
  .check-box-group > label {
    margin-bottom: 5px !important;
  }
}
.sidebar-content-box p {
  margin-bottom: 0 !important;
}
.sidebar-content-box p,
.sidebar-info-box p {
  line-height: 20px;
}
.section {
  font-size: 1rem !important;
  font-weight: 700 !important;
  line-height: 1.2;
  color: #180058;
}
.question_group {
  font-size: 0.9rem !important;
  font-weight: 500 !important;
  line-height: 1.2;
  color: #180058;
}

.space-bottom {
  margin-bottom: 10px;
}
@media (max-width: 768px) {
  .space-bottom {
    margin-bottom: 5px;
  }
  .modal-body-doc {
    max-height: 450px;
  }
}
.rotate {
  transform: rotate(-90deg);
}
@media (max-width: 767.2px) {
  .fc .fc-toolbar-title {
    font-size: 12px !important;
    color: #180058;
  }
}

@media (max-width: 768px) {
  .fc .fc-toolbar.fc-header-toolbar {
    padding: 11px 10px 11px 10px;
    border-bottom: 1px solid #d7dae2;
    height: 62px;
  }
}

@media (max-width: 768px) {
  .fc .fc-button {
    padding: 0.2em 0.35em !important;
  }
}

@media (max-width: 575.98px) {
  .msb-body {
    width: fit-content;
    padding: 15px;
  }
}

.name-on-home {
  font-size: 16px;
  color: #180058;
  font-weight: 700;
  padding-bottom: 8px;
}
@media (min-width: 768px) {
  .name-on-home {
    display: none;
  }
}
.fc-timegrid-event-harness {
  min-height: 2rem;
}
.bio-button {
  padding: 9px 13px;
  border: 1px solid #fff;
  border-radius: 4px;
  transition: transform 0.3s;
}
.bio-button:hover {
  transform: scale(1.1);
}
.advise-box {
  padding: 5px 10px;
  margin: 0 10px;
  width: 75px;
  border-radius: 4px;
  border: 1px solid #bdbdbd;
}
@media (max-width: 767.8px) {
  .np-right {
    justify-content: center;
  }
}
.custom-accordion .article {
  padding: 30px !important;
}
@media (max-width: 1670.98px) {
  .add-treatment-plan-panel {
    padding: 15px 100px 15px 15px;
  }
}
.MuiFormControl-root {
  width: 100%;
}

.MuiOutlinedInput-root {
  border-radius: 8px !important;
}
.MuiOutlinedInput-notchedOutline {
  border-radius: inherit;
  border-style: none !important;
}

.MuiOutlinedInput-root {
  border: 1px solid #e0e0e0;
}
.MuiOutlinedInput-root {
  border: 1px solid #e0e0e0;
  border-radius: 8px !important;
}
.MuiFormControl-root {
  border: 1px solid #e0e0e0;
  border-radius: 8px !important;
}
@media (max-width: 767.8px) {
  .MuiOutlinedInput-root {
    border: 1px solid #e0e0e0;
  }
}
.MuiOutlinedInput-input {
  padding: 23px 14px 10px 14px !important;
  padding-right: 0;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 23px 14px 10px 14px !important;
}
.score-parent {
  position: relative;
}
.score-child {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%);
}
.MuiButtonBase-root {
  border: 0;
}

.PrivatePickersSlideTransition-root .Mui-selected {
  background-color: #ff8f00 !important;
}
.MuiButtonBase-root:hover {
  background-color: #ff8f00 !important;
}
.MuiButton-root {
  color: #ff8f00 !important;
}
.MuiYearPicker-root .Mui-selected {
  background-color: #ff8f00 !important;
}
.MuiPaper-root {
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
}
.MuiPickersDay-today {
  background-color: #ff8f00 !important;
}
.np-right a > img {
  width: 20px;
}
.np-right a + a {
  margin-left: 10px;
}
.document-dropdown {
  position: absolute;
  right: 0px;
  top: 6px;
}
.fill-case {
  color: red;
  font-size: 16px;
}

.vs-box ul.documents-list li {
  width: 50% !important;
}
