.mcb-body-fullscreen {
  margin: 0px 0;
  background: #000;
  border-radius: 0px;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  padding-bottom: 54.6%;
  width: 100%;
  height: 100%;
}

.mcb-footer-fullscreen {
  width: 100%;
  position: fixed;
  bottom: 25px;
  left: 0;
  opacity: 0;
}

.mb-foor-app {
  display: flex;
  justify-content: center;
}

.mcb-footer-fullscreen:hover {
  opacity: 1;
}

.mcb-footer-fullscreen .mcb-left {
  /* color: #fff; */
}

.mcb-footer-fullscreen .mcb-right {
  /* color: #fff; */
}

.mcb-footer-fullscreen .mcb-mid .mcb-link {
  padding: 0 10px !important;
}

.mcb-footer-fullscreen .mcb-mid .mcb-link p {
  /* color: #fff; */
}

.mcb-body-fullscreen .doc-image video {
  object-fit: contain !important;
}

.video-box2 .mcb-footer-fullscreen .mcb-link {
  margin: 0px 0;
}

.fullscreen-button {
  z-index: 1;
}

.blockModal .modal-content {
  box-shadow: none !important;
}
.blockModal .modal-dialog {
  min-width: 300px;
}

.blockModal .tbm-box {
  padding: 20px !important;
}
.blockModal .tbm-body {
  margin-bottom: 10px !important;
}

.appointment-success-modal .modal-content {
  text-align: center;
  background-color: #582bb8 !important;
  color: #fff !important;
  padding: 80px 0;
}

.appointment-success-modal .modal-content img {
  width: 80px;
}

.appointment-success-modal .modal-content h4 {
  margin-bottom: 10px !important;
  margin-top: 20px;
}

.appointment-success-modal .modal-content .journey {
  color: #f68e3f !important;
  font-weight: bold;
  margin-bottom: 40px;
}

.mcb-screen-share {
  margin: 16px 0;
  background: #000;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  min-height: 400px;
  padding-bottom: 54.6%;
  height: 400px;
}

.share-remote {
  width: 175px;
  height: 124px;
  border-radius: 20px;
  overflow: hidden;
  border: 1px solid #fff;
}

.local-video {
  background-color: #000;
  width: 175px;
  height: 124px;
  border-radius: 20px;
  overflow: hidden;
  border: 1px solid #fff;
}

.local-video > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.fullscreen-share-button {
  width: 58px;
  height: 58px;
  border-radius: 50%;
  background: rgb(29 14 61 / 30%);
  display: flex;
  align-items: center;
  justify-content: center;
}
.fullscreen-share-button > img {
  width: 30px;
  margin-top: 5px;
}

.doc-share-image {
  width: 100%;
  overflow: hidden;
}
.doc-share-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.local-video .connect-text {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.local-video h5 {
  margin-bottom: 0;
  font-size: 20px;
  color: #dddbe2;
  font-weight: 700;
}

@media (max-width: 1670.98px) {
  .local-video {
    width: 150px;
    height: 90px;
    border-radius: 10px;
  }
  .local-video h5 {
    font-size: 18px;
  }
}

@media (max-width: 991.98px) {
  .local-video {
    width: 120px;
    height: 80px;
  }
}

@media (max-width: 767.98px) {
  .local-video {
    width: 70px;
    height: 50px;
  }
  .local-video h5 {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .mcb-footer-fullscreen {
    opacity: 1;
  }
  .mcb-footer-fullscreen {
    left: 0px;
    bottom: 20px;
  }
  .mcb-footer-fullscreen .mcb-left {
    display: none;
  }

  .mcb-footer-fullscreen .mcb-right {
    display: none;
  }

  .mcb-footer-fullscreen .mcb-footer {
    background: none;
    padding: 0 25px;
  }

  .mcb-footer-fullscreen .mcb-mid {
    margin: 0;
  }
  .schedule-day {
    min-width: 65px;
    margin-top: 10px;
  }
  .blockModal .btn {
    min-width: 10px !important;
  }
}
